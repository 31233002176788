import path from 'path';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { lending as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    SEO,
} from '@brandedholdings/react-components';

class Lending extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);


        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>Responsible Lending &amp; Marketing</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            <h4>Responsible Lending and Marketing</h4>
                            <p>{siteMeta.nameShort} strives to familiarize consumers like you and help protect from unfair and illegal lending practices. We encourage you to review your particular lender's policies as interest rates, requirements on repayments, and consequences of late or non-payment may vary.</p>
                            <h4>Late Payments</h4>
                            <p>It is important that you understand the consequences of making late payments. Each lender has its own terms and conditions for the loan and repayment of such, which we strongly encourage you to review before borrowing. Late payments have the potential to result in fees and penalties.</p>
                            <h4>Non-Payment</h4>
                            <p>{siteMeta.nameShort} recommends you pay your bills when they are due. Each lender that we work with hs their own terms and conditions for the implications of missing a payment. Non-payment typically results in collection procedures against you and you will no longer be able to receive any more loans from the lender.</p>
                            <h4>State Regulations</h4>
                            <p>{siteMeta.nameShort} requires all of its lenders abide by state and federal laws governing lending. Lenders in our network charge fees and interest rates in accordance to regulatory measures enacted by governments, state or federal.</p>
                            <h4>Collections</h4>
                            <p>If you miss a payment and do not attempt to quickly resolve the issue, you will likely have your loan subject to collections. You will be contacted via phone, email and regular mail. If you do not respond, an external collections agency will likely be used. Each lender has different collections policies which can be found in your lenders terms and conditions.</p>
                            <p>Please consult your lender for more details.</p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

Lending.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Lending;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;